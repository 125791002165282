export const getVehicleData = () => {
  return [
    {
      id: '200',
      label: '于万科技',
      children: [
        {
          id: '204',
          label: '阿克苏部',
          children: [
            {
              id: 't-1-01',
              label: '01小神童(新212112)'
            },
            {
              id: 't-1-02',
              label: '02小神童(新122112)'
            },
            {
              id: 't-1-03',
              label: '03小神童(新111212)'
            },
            {
              id: 't-1-04',
              label: '04小神童(新111213)'
            },
            {
              id: 't-1-05',
              label: '05小神童(新221222)'
            },
            {
              id: 't-1-06',
              label: '06小神童(新112222)'
            }
          ]
        }
      ]
    }
  ]
}
